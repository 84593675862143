import { ReactChild, useEffect, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';

import FACEBOOK from '../../static/images/facebook.svg';
import INSTAGRAM from '../../static/images/instagram.svg';

import Artwork from '../artwork';
import { useArtwork } from '../../context/Artwork';
import { usePublications } from '../../context/Publications';
import PublicationsModal from '../publications_modal';
import { Close, Menu } from '@material-ui/icons';

type NavigationProps = {
  children: ReactChild | ReactChild[];
};

export default function Navigation({ children }: NavigationProps) {
  const history = useHistory();
  const location = useLocation();

  const artist =
    location.pathname.includes('/artist') && location.pathname.length > 7;

  const exhibition =
    location.pathname.includes('/exhibition') &&
    !(
      location.pathname === '/exhibition-upcoming' ||
      location.pathname === '/exhibition-past'
    );

  const onlineExhibition = location.pathname.includes('/online-exhibition');

  const main = location.pathname === '/';

  const { artwork } = useArtwork();

  const { publications } = usePublications();

  const [isTop, setIsTop] = useState(false);

  const [isMenu, setIsMenu] = useState(false);

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsTop(false);
    } else {
      setIsTop(true);
    }
  };

  useEffect(() => {
    if (publications || artwork || isMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [publications, artwork, isMenu]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {isBrowser ? (
        <Box
          style={{
            backgroundColor: location.pathname.includes('/online-exhibition')
              ? '#F5F2EB'
              : '',
          }}
        >
          <div
            className={cn('header')}
            style={{
              backgroundColor:
                onlineExhibition && isTop
                  ? '#F5F2EB'
                  : isTop
                  ? '#f8f9fa'
                  : 'transparent',
              borderBottom: isTop ? '1px solid #ddd' : '1px solid transparent',
            }}
          >
            <span
              className={cn('header-logo')}
              onClick={() => history.push('/')}
            >
              EASELLY
            </span>

            <span
              className={cn('header-page')}
              onClick={() => history.push('/exhibition/onview')}
            >
              EXHIBITION
            </span>

            <span
              className={cn('header-page')}
              onClick={() => history.push('/artist')}
            >
              ARTISTS
            </span>

            <span
              className={cn('header-page')}
              onClick={() => {
                // history.push('/online-exhibition');
                window.alert('We are preparing for service.');
              }}
            >
              ONLINE EXHIBITION
            </span>

            <span
              className={cn('header-page')}
              onClick={() => history.push('/artfair')}
            >
              FAIRS
            </span>

            <span
              className={cn('header-page')}
              onClick={() => {
                history.push('/publications');
              }}
            >
              PUBLICATIONS
            </span>

            {/* <span
              className={cn('header-page')}
              onClick={() => history.push('/about')}
              style={{
                color: main && !isTop ? '#fff' : '',
              }}
            >
              ABOUT
            </span> */}
          </div>

          <div
            className={cn('contents')}
            style={{
              maxWidth: artist || main || exhibition ? '' : '1920px',
            }}
          >
            {children}
          </div>

          <div className={cn('footer')}>
            <div className={cn('footer-box')} style={{ width: '50%' }}>
              <span className={cn('footer-box-title')}>About</span>

              <div className={cn('footer-box-text')}>
                easelly is committed to developing international
                <br />
                collaboration and exchange of art.
              </div>

              <div className={cn('footer-box-text_2')}>
                3F, 127 Jwasuyeong-ro, Suyeong-gu, Busan, Korea
                <br />
                (48214)
              </div>

              <div className={cn('footer-box-text_wrap')}>
                <span className={cn('footer-box-text_wrap-title')}>
                  OPEN ON
                </span>

                <span className={cn('footer-box-text_wrap-text')}>
                  TUESDAY - SATURDAY 10:30 - 18:30
                </span>
              </div>

              <div className={cn('footer-box-text_wrap')}>
                <span className={cn('footer-box-text_wrap-title')}>
                  LUNCH BREAK
                </span>

                <span className={cn('footer-box-text_wrap-text')}>
                  13:00 - 14:00
                </span>
              </div>

              <div className={cn('footer-box-text_wrap')}>
                <span className={cn('footer-box-text_wrap-title')}>
                  CLOSED ON
                </span>

                <span className={cn('footer-box-text_wrap-text')}>
                  SUNDAY & MONDAY
                </span>
              </div>
            </div>

            <div className={cn('footer-box')} style={{ width: '25%' }}>
              <span className={cn('footer-box-title')}>Follow us</span>

              <div
                className={cn('footer-box-icon_wrap')}
                onClick={() =>
                  window.open(
                    'https://www.facebook.com/profile.php?id=100071787024112',
                    '_blank'
                  )
                }
              >
                <img
                  className={cn('footer-box-icon_wrap-icon')}
                  src={FACEBOOK}
                  alt='icon'
                  draggable={false}
                />

                <span className={cn('footer-box-icon_wrap-text')}>
                  FACEBOOK
                </span>
              </div>

              <div
                className={cn('footer-box-icon_wrap')}
                onClick={() =>
                  window.open('https://www.instagram.com/easelly_art', '_blank')
                }
              >
                <img
                  className={cn('footer-box-icon_wrap-icon')}
                  src={INSTAGRAM}
                  alt='icon'
                  draggable={false}
                />

                <span className={cn('footer-box-icon_wrap-text')}>
                  INSTAGRAM
                </span>
              </div>
            </div>

            <div className={cn('footer-box')} style={{ width: '25%' }}>
              <span className={cn('footer-box-title')}>Contact</span>

              <span className={cn('footer-box-contact_title')}>E-mail</span>

              <span className={cn('footer-box-contact_text')}>
                official@easelly.net
              </span>
            </div>
          </div>
        </Box>
      ) : (
        <MobileBox
          style={{
            backgroundColor: location.pathname.includes('/online-exhibition')
              ? '#F5F2EB'
              : '',
          }}
        >
          <div
            className={cn('header')}
            style={{
              backgroundColor:
                location.pathname.includes('/online-exhibition') && !isMenu
                  ? '#F5F2EB'
                  : '',
            }}
          >
            <span
              className={cn('header-logo')}
              onClick={() => history.push('/')}
            >
              EASELLY
            </span>

            {isMenu ? (
              <Close
                className={cn('header-icon')}
                onClick={() => setIsMenu(!isMenu)}
              />
            ) : (
              <Menu
                className={cn('header-icon')}
                onClick={() => setIsMenu(!isMenu)}
              />
            )}
          </div>

          <div className={cn('contents')}>{children}</div>

          <div className={cn('footer')}>
            <div className={cn('footer-box')}>
              <span className={cn('footer-box-title')}>About</span>

              <div className={cn('footer-box-text')}>
                easelly is committed to developing international
                <br />
                collaboration and exchange of art.
              </div>

              <div className={cn('footer-box-text_2')}>
                3F, 127 Jwasuyeong-ro, Suyeong-gu, Busan, Korea
                <br />
                (48214)
              </div>

              <div className={cn('footer-box-text_wrap')}>
                <span className={cn('footer-box-text_wrap-title')}>
                  OPEN ON
                </span>

                <span className={cn('footer-box-text_wrap-text')}>
                  TUE - SAT 10:30 - 18:30
                </span>
              </div>

              <div className={cn('footer-box-text_wrap')}>
                <span className={cn('footer-box-text_wrap-title')}>
                  LUNCH BREAK
                </span>

                <span className={cn('footer-box-text_wrap-text')}>
                  13:00 - 14:00
                </span>
              </div>

              <div className={cn('footer-box-text_wrap')}>
                <span className={cn('footer-box-text_wrap-title')}>
                  CLOSED ON
                </span>

                <span className={cn('footer-box-text_wrap-text')}>
                  SUNDAY & MONDAY
                </span>
              </div>
            </div>

            <div className={cn('footer-box')}>
              <span className={cn('footer-box-title')}>Follow us</span>

              <div
                className={cn('footer-box-icon_wrap')}
                onClick={() =>
                  window.open(
                    'https://www.facebook.com/profile.php?id=100071787024112',
                    '_blank'
                  )
                }
              >
                <img
                  className={cn('footer-box-icon_wrap-icon')}
                  src={FACEBOOK}
                  alt='icon'
                  draggable={false}
                />

                <span className={cn('footer-box-icon_wrap-text')}>
                  FACEBOOK
                </span>
              </div>

              <div
                className={cn('footer-box-icon_wrap')}
                onClick={() =>
                  window.open('https://www.instagram.com/easelly_art', '_blank')
                }
              >
                <img
                  className={cn('footer-box-icon_wrap-icon')}
                  src={INSTAGRAM}
                  alt='icon'
                  draggable={false}
                />

                <span className={cn('footer-box-icon_wrap-text')}>
                  INSTAGRAM
                </span>
              </div>
            </div>

            <div className={cn('footer-box')}>
              <span className={cn('footer-box-title')}>Contact</span>

              <span className={cn('footer-box-contact_title')}>E-mail</span>

              <span className={cn('footer-box-contact_text')}>
                official@easelly.net
              </span>
            </div>
          </div>

          {isMenu ? (
            <div className={cn('menu')}>
              <span
                className={cn('menu-page')}
                onClick={() => history.push('/exhibition/onview')}
              >
                EXHIBITION
              </span>

              <span
                className={cn('menu-page')}
                onClick={() => history.push('/artist')}
              >
                ARTISTS
              </span>

              <span
                className={cn('menu-page')}
                onClick={() => {
                  // history.push('/online-exhibition');
                  window.alert('We are preparing for service.');
                }}
              >
                ONLINE EXHIBITION
              </span>

              <span
                className={cn('menu-page')}
                onClick={() => history.push('/artfair')}
              >
                FAIRS
              </span>

              <span
                className={cn('menu-page')}
                onClick={() => {
                  history.push('/publications');
                }}
              >
                PUBLICATIONS
              </span>
            </div>
          ) : (
            <></>
          )}
        </MobileBox>
      )}

      {artwork ? <Artwork /> : <></>}

      {publications ? <PublicationsModal /> : <></>}
    </>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    padding: 0 50px;

    &-logo {
      width: fit-content;
      height: fit-content;
      font-size: 26px;
      font-weight: 600;
      letter-spacing: 5px;
      user-select: none;
      margin-right: auto;
      margin-left: 0;
      cursor: pointer;
    }

    &-page {
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      user-select: none;
      cursor: pointer;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .contents {
    width: 100%;
    height: fit-content;
    min-height: 100%;
  }

  .footer {
    width: 100%;
    max-width: 1920px;
    height: fit-content;
    /* border-top: 1px solid #ddd; */
    display: flex;
    flex-direction: row;
    padding: 50px 100px;
    background-color: #000;

    &-box {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      &-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 50px;
        color: #fff;
      }

      &-text,
      &-text_2 {
        font-size: 16px;
        line-height: 1.8;
        color: #fff;
      }

      &-text_2 {
        color: #adb5bd;
        margin-bottom: 20px;
      }

      &-text_wrap {
        width: 400px;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        &-title {
          font-size: 16px;
          font-weight: 500;
          color: #fff;
        }

        &-text {
          color: #fff;
        }
      }

      &-icon_wrap {
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-bottom: 30px;
        cursor: pointer;
        user-select: none;

        &:hover {
          .footer-box-icon_wrap-text {
            text-decoration: underline;
          }
        }

        &-icon {
          width: 20px;
          height: auto;
          margin-right: 20px;
        }

        &-text {
          font-size: 16px;
          font-weight: 400;
          color: #fff;
        }
      }

      &-contact_title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        color: #fff;
      }

      &-contact_text {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 30px;
        color: #fff;
      }
    }
  }
`;

const MobileBox = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    background-color: #f8f9fa;
    border-bottom: 1px solid #ddd;
    padding: 0 20px;

    &-logo {
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 3px;
      user-select: none;
      margin: 0 auto 0 0;
    }

    &-icon {
      width: 24px;
      height: auto;
    }
  }

  .contents {
    width: 100%;
    height: fit-content;
    margin-top: 60px;
  }

  .footer {
    width: 100%;
    height: fit-content;
    border-top: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    background-color: #000;

    &-box {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      &-title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        color: #fff;
      }

      &-text,
      &-text_2 {
        line-height: 1.8;
        color: #fff;
      }

      &-text_2 {
        color: #adb5bd;
        margin-bottom: 10px;
      }

      &-text_wrap {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 30px;
        }

        &-title {
          font-weight: 400;
          color: #fff;
        }

        &-text {
          color: #fff;
        }
      }

      &-icon_wrap {
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-bottom: 20px;
        cursor: pointer;
        user-select: none;

        &:last-child {
          margin-bottom: 30px;
        }

        &-icon {
          width: 20px;
          height: auto;
          margin-right: 10px;
        }

        &-text {
          font-size: 14px;
          font-weight: 400;
          color: #fff;
        }
      }

      &-contact_title {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
        color: #adb5bd;
      }

      &-contact_text {
        margin-bottom: 20px;
        color: #fff;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .menu {
    width: 100%;
    height: fit-content;
    min-height: calc(100vh - 60px);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    background-color: #f8f9fa;

    &-page {
      width: 100%;
      height: fit-content;
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      padding: 20px;
      letter-spacing: 1px;
      border-bottom: 1px solid #ddd;
      color: #000;
      user-select: none;
    }
  }
`;
